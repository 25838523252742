*,
*:after,
*:before {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body,
html {
  height: 100%;
}

input:focus,
textarea:focus {
  outline: 0;
}

a,
button {
  font-weight: 400;
}

a {
  text-decoration: none;
}

button {
  border: 0;
  background: none;
  cursor: pointer;
}

img {
  max-width: 100%;
  height: auto;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  font-weight: 400;
  font-size: 36px;
}

a{
  text-decoration: none;
}

a:visited{
  color: black;
}

a:link{
  color: black;
}
