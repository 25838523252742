@import "../../scss/styles.scss";

.main_container {
    margin-top: 5rem;
    max-width: 1200px;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    flex-direction: column;

    @media (max-width: 700px) {
        margin-top: 2rem;
    }

    .flex_cont {
        display: flex;
        justify-content: space-between;
        align-items: center;

        @media (max-width: 700px) {
            flex-direction: column;
        }

        .title {
            height: fit-content;
            font-weight: 700;
            font-style: inherit;
        
            @media (max-width: 700px) {
                font-weight: 600;
                margin-bottom: 1rem;
                font-size: 1.5rem;
            }
        }

        img {
            -webkit-box-shadow: 0px 0px 61px 0px rgba(245, 4, 4, 0.2);
            -moz-box-shadow: 0px 0px 61px 0px rgba(245, 4, 4, 0.2);
            box-shadow: 0px 0px 61px 0px rgba(245, 4, 4, 0.2);
            border-radius: 100%;
            cursor: pointer;
        }
    }

    .videos_container{
        margin-top: 2rem;

        .divider{
            width: 100%;
            height: 2px;
            background-color: rgba(221, 220, 220, 0.507);
        }

        .best_title{
            margin-top: 1rem;
            text-align: center;
            font-weight: 700;
            font-style: inherit;

            @media (max-width: 700px) {
                font-size: 1.5rem;
            }
        }
    
        .videos_c{
            margin-top: 1rem;
            display: grid;
            grid-gap: 1.5rem;
            grid-template-columns: repeat(4, 1fr);

            @media (max-width: 700px) {
                grid-template-columns: repeat(2, 1fr);
                grid-gap: 0.5rem;
                margin: 1rem;
            }
        }
    }
}