@import "../../scss/styles.scss";

.nav_container {
    width: 100%;
    background-color: white;
    -webkit-box-shadow: -1px 1px 8px 0px rgba(34, 60, 80, 0.2);
    -moz-box-shadow: -1px 1px 8px 0px rgba(34, 60, 80, 0.2);
    box-shadow: -1px 1px 8px 0px rgba(34, 60, 80, 0.2);

    .title_container {
        padding: 1rem;
        max-width: 1200px;
        margin-left: auto;
        margin-right: auto;

        .title_nav {
            font-size: 1.2rem;
            font-weight: 600;
        }
    }
}